import { render, staticRenderFns } from "./helpCenterInfo-detail.vue?vue&type=template&id=f8cc55fe&scoped=true"
import script from "./helpCenterInfo-detail.vue?vue&type=script&lang=js"
export * from "./helpCenterInfo-detail.vue?vue&type=script&lang=js"
import style0 from "./helpCenterInfo-detail.vue?vue&type=style&index=0&id=f8cc55fe&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8cc55fe",
  null
  
)

export default component.exports